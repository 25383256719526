@keyframes slideUp {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -85px, 0);
  }
}

.all_hints_wrapper {
  &.slide {
    animation: slideUp 0.5s;
    transform: translate3d(0, -85px, 0);
  }
}

.carousel_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .archived_game_disclaimer {
    color: rgb(100, 100, 100);
    font-size: 0.65rem;
    margin: 0;
    &.dark {
      color: rgb(200, 200, 200);
    }
  }
}

button.product_purchase_button {
  position: absolute;
  bottom: -0.9rem;
  left: 0;
  right: 0;
  width: 236px;
  min-width: 236px;
  max-width: 236px;
  margin: 0 auto;
  color: #000;
  background: #fff;
  border: 1px solid #000;
  outline: none;
  font-size: 0.9rem;
  font-weight: 600;
  height: 2.875rem;
  backdrop-filter: blur(5px);
  transition: background 0.5s ease, color 0.5s ease;
  a {
    position: relative;
    color: #000;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      z-index: 99;
    }
    img {
      height: 70px;
      width: 70px;
      margin-left: 0.2rem;
      object-fit: contain;

      &.hm_logo {
        height: 25px;
        width: 25px;
        margin-left: 0.35rem;
      }
      &.gap_logo {
        height: 100%;
        z-index: 0;
        object-fit: cover;
        margin-left: -0.65rem;
      }

      &.macys_logo {
        margin-top: 0.05rem;
      }

      &.bloomingdales_logo,
      &.sarah_flint_logo {
        height: 100px;
        width: 100px;
        margin-left: 0.3rem;
      }

      &.bloomingdales_logo {
        margin-top: 0.15rem;
      }

      &.burberry_logo,
      &.laura_logo,
      &.farfetch_logo {
        height: 90px;
        width: 90px;
      }

      &.burberry_logo {
        margin-left: 0.05rem;
      }

      &.garage_logo {
        margin-top: 0.025rem;
      }

      &.maison_de_fashion_logo {
        height: 110px;
        width: 110px;
      }

      &.psycho_bunny_logo {
        height: 130px;
        width: 130px;
        margin-top: -0.65rem;
        margin-left: -0.4rem;
      }

      &.matches_fashion_logo,
      &.ralph_lauren_logo {
        height: 130px;
        width: 130px;
        margin-left: 0.35rem;
      }

      &.walmart_logo,
      &.kswiss_logo,
      &.derek_lam_logo,
      &.kohls_logo {
        height: 75px;
        width: 75px;
        margin-left: 0.4rem;
        margin-top: 0.025rem;
      }

      &.walmart_logo {
        margin-left: 0.3rem;
      }

      &.movado_logo,
      &.forever21_logo {
        height: 65px;
        width: 65px;
        margin-top: 0.025rem;
      }

      &.marshalls_logo,
      &.jcrew_logo {
        height: 60px;
        width: 60px;
        margin-top: 0.025rem;
      }

      &.dvf_logo {
        margin-left: 0.35rem;
        height: 75px;
        width: 75px;
      }

      &.tj_maxx_logo {
        height: 75px;
        width: 75px;
        margin-top: 0.3rem;
        margin-left: 0.25rem;
      }

      &.kipling_logo {
        height: 45px;
        width: 45px;
        margin-top: 0.1rem;
        margin-left: 0.35rem;
      }

      &.dior_logo,
      &.daily_haute_logo {
        height: 40px;
        width: 40px;
        margin-left: 0.05rem;
      }

      &.gucci_logo,
      &.dior_logo {
        margin-left: 0.3rem;
      }

      &.target_logo,
      &.gucci_logo {
        margin-top: 0.05rem;
      }

      &.target_logo {
        margin-left: 0.3rem;
      }

      &.amazon_logo {
        height: 60px;
        width: 60px;
        margin-top: 0.5rem;
        margin-left: 0.3rem;
      }

      &.jcpenney_logo {
        height: 55px;
        width: 55px;
        margin-top: 0.175rem;
        margin-left: 0.3rem;
      }

      &.jcrew_logo,
      &.farfetch_logo,
      &.revolve_logo,
      &.forever21_logo {
        margin-left: 0.35rem;
      }
    }
  }
  svg {
    margin-right: 0.25rem;
  }
  @media only screen and (min-width: 500px) {
    &:hover {
      animation: none;
      background: #000;
      color: #fff;
      border: 1px solid rgb(200, 200, 200);
      a {
        color: #fff;
        img {
          -webkit-filter: invert(1);
          filter: invert(1);
          &.walmart_logo,
          &.target_logo,
          &.marshalls_logo,
          &.tj_maxx_logo,
          &.amazon_logo,
          &.daily_haute_logo,
          &.jcpenney_logo,
          &.hm_logo,
          &.gap_logo,
          &.macys_logo {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

.product_details_container {
  width: 100%;
  padding: 4.75rem 0.5rem 0.75rem 0.5rem;
  height: 50%;
  position: relative;
  background: #fff;
  .carousel_image {
    width: 100%;
    margin: 0;
    padding: 0;
    max-width: 234px;
    min-width: 234px;
    max-height: 350px;
    min-height: 100%;
    object-position: 50% 50%;
    object-fit: contain;
    @media only screen and (max-width: 768px) {
      width: 60% !important;
      max-height: 250px;
    }
  }

  .secondThirdHintContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 0.25rem;
  }

  .hint_container {
    font-family: "Helvetica Neue";
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &.gender {
      display: flex;
      align-items: flex-end;
      margin-right: 0.5rem;
    }
    .hint_value {
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 4.5rem;
      line-height: 1.5rem;
      span {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      span:first-letter {
        text-transform: uppercase;
      }
    }
    .hidden {
      filter: blur(7px);
      font-weight: 700;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    h2.product_name {
      max-width: 480px;
      width: 100%;
      word-wrap: break-word;
      font-weight: 500;
      font-size: 2rem;
      color: #000;
      margin: 0;
      padding-right: 1rem;
      text-align: left;
      margin-top: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0 0.4rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
    p {
      color: #000;
      text-align: left;
      margin: 0;
      padding: 0;
      max-width: 350px;
      display: flex;
      flex-wrap: wrap;
      gap: 0 0.3rem;
      span {
        white-space: nowrap;
      }
    }
    &.dark {
      h2.product_name,
      span,
      p {
        color: #fff;
      }
    }
  }

  .arrow_container {
    z-index: 1;
    position: absolute;
    left: -10rem;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 65px;
    height: 65px;
    cursor: pointer;
    &.right {
      left: auto;
      right: -10rem;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    @media only screen and (max-width: 768px) {
      width: 50px;
      height: 50px;
      left: -0.75rem;
      &.right {
        right: -0.75rem;
      }
    }
  }

  &.dark {
    background: #010408;
    .arrow_container {
      filter: brightness(0) invert(1);
    }
  }
}

.guesses_container {
  margin-top: 2rem;
  .guess_row {
    font-family: "Helvetica Neue";
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0;
    color: #000;
    p {
      margin: 0;
      padding: 0;
    }
    p:first-child {
      text-align: left;
    }
    p:last-child {
      text-align: right;
    }
    &:nth-child(odd) {
      background: rgb(250, 250, 250);
    }
    &:nth-child(even) {
      background: #fff;
    }
    &.dark {
      p {
        color: #fff;
      }
      &:nth-child(odd) {
        background: rgb(15, 15, 15);
      }
      &:nth-child(even) {
        background: #000;
      }
    }
  }
}

.loading_shimmers_container {
  .loading_shimmer {
    width: 100% !important;
    height: 350px !important;
    @media only screen and (max-width: 768px) {
      height: 250px !important;
    }
  }
  .loading_shimmer_input {
    width: 100% !important;
    height: 46px !important;
    margin: 2rem 0;
  }
  .loading_shimmer_hint_title {
    width: 50% !important;
    height: 34px !important;
  }
  .loading_shimmer_hint_third,
  .loading_shimmer_hint_description {
    width: 25% !important;
    height: 22px !important;
    margin: 1rem 0;
  }
  .secondThirdShimmerHintsContainer {
    display: flex;
    justify-content: space-between;
  }
}

.carousel_slider {
  max-width: 18.3125rem;
  margin: 0 auto;
  right: 0;
  list-style: none;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 2.25rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  li {
    width: 100%;
    height: 0.125rem;
    background: #979797;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    height: 100%;
    width: 100%;
    background: rgb(225, 225, 225);
    color: transparent;
    outline: none;
    border: none;
    transition: background 0.5s ease;
    &[aria-selected="true"] {
      background: #000;
    }
  }
  &.dark {
    button {
      background: rgb(100, 100, 100);
      &[aria-selected="true"] {
        background: #fff;
      }
    }
  }
}
