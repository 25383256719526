header.app_header {
  position: fixed;
  width: 100vw;
  min-height: 41px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8dce1;
  z-index: 99999;
  background: #fff;
  @media only screen and (min-width: 768px) {
    min-height: 50px;
  }

  h1.header_logo {
    position: absolute;
    left: 0;
    right: 0;
    top: -0.5rem;
    font-size: 2rem !important;
    color: #000;
    font-family: Nimbus Sans Extended D;
    font-weight: 700 !important;
    margin: 0.3rem auto 0 auto !important;
  }

  &.dark {
    background: #161b22;
    border-bottom: 1px solid #323232;
    h1.header_logo {
      color: #fff;
    }
  }
}

.inner_header_wrapper {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  svg {
    cursor: pointer;
    z-index: 9;
  }

  .left_side_header,
  .right_side_header {
    display: flex;
    align-items: center;
  }

  .left_side_header {
    .header_question_circle {
      left: 3.05rem;
      position: absolute;
    }
  }

  .right_side_header {
    margin-right: 1rem;
    svg {
      margin: 0 0 0 0.5rem;
    }
  }

  .bm-overlay {
    background: rgba(255, 255, 255, 0.85) !important;
  }
  .bm-menu {
    background: rgb(220, 220, 220);
    outline: 1px solid rgb(150, 150, 150);
  }
  &.dark {
    .bm-overlay {
      background: rgba(0, 0, 0, 0.85) !important;
    }
    .bm-menu {
      background: rgb(22, 27, 34);
      outline: 1px solid #323232;
    }
  }
}
