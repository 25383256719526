.app_container {
  text-align: center;
  max-width: 480px;
  min-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin: 0 auto;
  &.dark {
    background: #010408;
    outline: 1px solid #30363d;
  }
}

.cached_gifs {
  display: none;
}
