.archive_modal_scroll_container {
  display: relative;
  overflow: auto;
  top: -35px;
  @media only screen and (min-width: 768px) {
    max-height: 90vh;
  }
}

.archive_prompt {
  margin: 0 0 1.5rem 0;
}

.archived_modal {
  @media only screen and (max-width: 400px) {
    max-height: 650px;
    top: 0;
  }
}

.react-calendar {
  font-family: "Helvetica Neue" !important;
  color: #000;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;

  .react-calendar__month-view__days {
    background: rgb(240, 240, 240);
  }

  .react-calendar__tile--now:not(.react-calendar__tile--active) {
    abbr {
      color: #000 !important;
    }
  }

  .react-calendar__tile--active {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .current_date_star {
      fill: transparent;
      outline: 1px solid #fff;
      stroke: #fff;
      stroke-width: 30px;
    }

    abbr {
      position: absolute;
      z-index: 1;
      color: #fff !important;
    }
  }

  .react-calendar__month-view__weekdays {
    background: #fff !important;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    font-size: 0.9rem;
    background: #fff !important;
    color: #000;
    font-weight: 700;
  }

  .react-calendar__tile {
    position: relative;
    color: #000;
    font-weight: 500;
    min-height: 36px;

    &.react-calendar__tile--hasActive {
      background: #006edc !important;
      abbr {
        color: #fff !important;
      }
    }

    &:not(.react-calendar__tile--active) {
      background: #fff;
    }

    &.react-calendar__tile--now.react-calendar__month-view__days__day {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      abbr {
        top: 0.7rem;
        position: absolute;
        z-index: 1;
        color: #000;
      }
    }

    &:hover:not([disabled]):not(.react-calendar__tile--active) {
      background: rgb(215, 215, 215) !important;
    }

    &[disabled] {
      background: rgb(150, 150, 150);
      color: #000;
      font-weight: 200;
    }
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000;
  }

  .react-calendar__navigation__arrow {
    &[disabled] {
      background: rgb(150, 150, 150) !important;

      svg {
        opacity: 0.2;
      }
    }
  }

  &.dark {
    .react-calendar__month-view__days {
      background: rgb(50, 50, 50);
    }

    .react-calendar__month-view__weekdays {
      background: rgb(30, 30, 30) !important;
      color: #fff;
      border-bottom: 1px solid rgb(75, 75, 75);
    }

    .react-calendar__navigation__arrow,
    .react-calendar__navigation__label {
      background: rgb(0, 0, 0) !important;
      color: #fff;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      abbr {
        color: #fff !important;
      }
    }

    .react-calendar__tile {
      position: relative;
      color: #fff;
      font-weight: 500;

      &:not(.react-calendar__tile--active) {
        background: rgb(0, 0, 0);
      }

      &:hover:not([disabled]):not(.react-calendar__tile--active) {
        background: rgb(150, 150, 150) !important;
      }

      &[disabled] {
        background: rgb(50, 50, 50);
        color: #dedede;
        font-weight: 200;
      }
    }

    .react-calendar__navigation__arrow {
      &.react-calendar__navigation__prev2-button,
      &.react-calendar__navigation__next-button {
        border-left: 1px solid rgb(75, 75, 75);
      }

      &.react-calendar__navigation__prev-button,
      &.react-calendar__navigation__next2-button {
        border-right: 1px solid rgb(75, 75, 75);
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #fff;
    }

    .react-calendar__navigation__arrow {
      &[disabled] {
        background: rgb(75, 75, 75) !important;

        svg {
          opacity: 0.2;
        }
      }
    }
  }

  .react-calendar__navigation {
    margin-bottom: 0;
    border-bottom: 1px solid rgb(75, 75, 75);
  }

  .react-calendar__year-view__months__month {
    border-right: 1px solid rgb(75, 75, 75);
    border-bottom: 1px solid rgb(75, 75, 75);
  }

  .current_date_star {
    position: absolute;
    top: -0.025rem;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    height: 110%;
    opacity: 1;
    fill: transparent;
    outline: 1px solid #000;
    stroke: #000;
    stroke-width: 30px;
    &.dark {
      fill: yellow;
      opacity: 0.6;
      stroke: transparent;
    }
  }

  abbr[title] {
    text-decoration: none;
  }
}

.archive_results_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 5rem;

  .archive_loading_container {
    margin: 2rem 0 3rem 0;
  }

  .archive_date_container {
    border-top: 1px solid rgb(65, 65, 65);
    border-bottom: 1px solid rgb(65, 65, 65);
    background: #fff;
    width: 500px;
    margin: 2rem 0 1.5rem 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p,
    h2,
    h3 {
      margin: 0;
      padding: 0;
      color: #000;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
    }

    &.dark {
      background: rgb(30, 30, 30);
      p,
      h2,
      h3 {
        color: #ffff;
      }
    }
  }

  .archive_date_products_container {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5rem;

    .archive_product_preview {
      position: relative;
      width: 100%;
      height: 100px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      .archive_product_image_container {
        display: flex;
        width: 100px;
        height: 100px;
        aspect-ratio: 1 / 1;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: right 0.5s ease-in;
        &.details_shown {
          right: 100%;
        }
        @media only screen and (max-width: 768px) {
          width: 100px;
        }
        img {
          width: 100%;
          object-fit: contain;
          border: 1px solid rgb(185, 185, 185);
        }
        &.dark {
          img {
            border: 1px solid rgb(225, 225, 225);
          }
        }
      }
      .archive_product_preview_details {
        margin-left: 7.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
        transition: opacity 0s ease 0s;
        &.details_shown {
          transition: opacity 0.5s ease 0.5s;
          opacity: 1;
        }
        @media only screen and (max-width: 768px) {
          width: 100px;
          height: 100px;
        }
        .archive_product_left_details {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          h3 {
            font-weight: 700 !important;
            font-size: 1.1rem;
            margin: 0;
          }
          .preview_price {
            margin: 0;
            font-size: 0.9rem;
          }
          p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            &::first-letter {
              text-transform: uppercase;
            }
            margin: 0;
            font-size: 0.85rem;
            width: 275px;
            @media only screen and (max-width: 410px) {
              width: 150px;
            }
            @media only screen and (min-width: 411px) and (max-width: 430px) {
              width: 250px;
            }
            @media only screen and (min-width: 431px) and (max-width: 550px) {
              width: 250px;
            }
            @media only screen and (min-width: 551px) and (max-width: 768px) {
              width: 300px;
            }
          }
          @media only screen and (min-width: 768px) {
            h3 {
              font-size: 1.35rem;
            }
            .preview_price {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

.guess_button.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;

  &:hover {
    background: initial;
    background: rgb(46, 160, 66);
  }

  &:active,
  &:focus {
    background: initial;
    background: rgb(46, 160, 66);
  }
}

.archived_buttons_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  margin-top: 1rem;

  .archive_product_link {
    position: relative;
    background: #fff;
    outline: 1px solid #000;
    margin: 0.1rem 0 0 0;
    @media only screen and (min-width: 768px) {
      margin: 0.05rem 0 0 0;
    }
  }
}

.archived_play_button {
  width: 100%;
  min-width: 236px;
  font-weight: 700;
  margin-top: 2rem;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.reveal_price_button {
  min-width: 236px;
  background: transparent;
  border: 1px solid #000;
  margin: 0;
  color: #000;
  &.dark {
    border: 1px solid #fff;
    color: #fff;
  }
}

.product_reveal_button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .spoiler_warning {
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: -1rem;
    color: #000;

    &.dark {
      color: #fff;
    }

    &.open {
      color: transparent !important;
    }
  }
}

.archived_play_button {
  margin-bottom: 0.5rem !important;
}

.archived_collapse {
  text-align: center;
  margin: 1.5rem 0 0 0;
  padding: 0;

  p {
    padding: 0 1rem;

    @media only screen and (min-width: 768px) {
      padding: 0 2rem;
    }
  }

  span {
    font-weight: 600;
  }
}
