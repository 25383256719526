@keyframes incorrect-shake {
  10% {
    transform: translateX(-1px);
  }
  20% {
    transform: translateX(2px);
  }
  30% {
    transform: translateX(-4px);
  }
  40% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-4px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}

@mixin incorrect-shake-animation {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: incorrect-shake;
}

@keyframes slideIn {
  0% {
    transform: translate3d(0, 500px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.winner_container {
  animation: slideIn 0.5s;
  position: relative;
  height: 100%;
  width: 100%;
  background: rgb(245, 246, 248);
  border-radius: 2.1875rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 2rem 0 3rem 0;
  text-align: center;
  margin-top: -3.5rem;
  margin-bottom: 5rem;
  overflow-x: hidden;
  &.loss {
    @include incorrect-shake-animation;
    border: 5px solid #e32;
    .winner_final_price {
      color: #e32;
      font-weight: 800;
    }
  }

  .global_stats_statement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
  }

  .loss_statement_container,
  .winner_statement_container {
    b.guesses_link {
      cursor: pointer;
      border-bottom: 1px solid #000;
      opacity: 1;
      transition: opacity 0.5s ease;
      margin-top: 0.5rem;
      font-size: 0.75rem;
      &:hover {
        opacity: 0.5;
      }
    }
    .winner_gif_container {
      position: relative;
      cursor: pointer;
      img.loss_gif,
      img.winner_gif {
        width: 80%;
        margin: 1.5rem 0;
        max-height: 207px;
        object-fit: cover;
        object-position: top;
        @media only screen and (max-width: 768px) {
          max-height: 167px;
        }
      }
      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .winner_statement_inner_container,
    .loss_statement_inner_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .winner_guesses_number {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }
  }

  p {
    color: #000;
    position: relative;
    span.emoji {
      position: absolute;
      margin: 0 1rem;
      font-size: 2.5rem;
      &.left {
        top: 0;
        left: 4rem;
      }
      &.right {
        top: 0;
        right: 4rem;
      }

      @media only screen and (min-width: 200px) and (max-width: 400px) {
        &.left {
          left: 0.75rem;
        }
        &.right {
          right: 0.75rem;
        }
      }

      @media only screen and (min-width: 401px) and (max-width: 500px) {
        &.left {
          left: 2rem;
        }
        &.right {
          right: 2rem;
        }
      }
    }
  }

  .actual_price_container {
    margin-bottom: 1rem;
    p {
      margin: 0;
    }
    .acceptable_range_container {
      font-size: 0.6rem;
      margin-top: 0.5rem;
    }
  }
  .winner_final_price {
    font-family: Futura Std Book;
    font-size: 2.125rem;
    font-weight: 300;
    color: rgb(51, 51, 51);
    letter-spacing: normal;
  }
  h2 {
    color: #000;
  }

  .winner_countdown_timer {
    font-size: 1.5rem;
    margin-top: 2rem;
    color: #000;
    .statistics_time_disclaimer {
      font-size: 0.6rem;
      p {
        margin: 0;
      }
    }
    span {
      font-weight: 700;
    }
  }

  &.dark {
    background: rgb(41, 46, 51);
    box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
    p {
      color: #fff;
    }
    .winner_countdown_timer {
      color: #fff;
    }
    .winner_final_price {
      color: rgb(204, 204, 204);
    }
    b.guesses_link {
      border-bottom: 1px solid #fff;
    }
  }
}

.react-tooltip {
  z-index: 9999999999999 !important;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-out !important;
  opacity: 0 !important;
  visibility: visible;
  margin: 0 auto;
  font-size: 0.85rem !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: 700;
  outline: 1px solid #fff;
  &.dark {
    background: #fff;
    color: #000;
    outline: 1px solid #000;
  }
}

.react-tooltip.show {
  visibility: visible;
  opacity: 1 !important;
}

.modal_guess_row {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-family: "Helvetica Neue";
  font-weight: 500;
  justify-items: center;

  &.descriptors {
    margin-top: 2rem;
    p {
      font-size: 0.9rem;
      border-bottom: 1px solid #000;
    }
    &.dark {
      p {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .red {
    color: red;
  }
  .green {
    color: green;
  }
  &.dark {
    .red {
      color: #cc2936;
    }
    .green {
      color: rgb(107, 168, 100);
    }
  }
}

.bottom_buttons_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 1.5rem 2rem 0 2rem;
    button {
      width: 100%;
      min-width: auto;
      max-width: auto;
    }
  }

  a.twitter_share_link {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    width: 50%;
    @media only screen and (max-width: 768px) {
      min-width: 236px;
      max-width: 236px;
    }
    color: #fff;
    background: #000;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875rem;
    margin: 0;
    margin-top: 1rem;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.5s ease;
    svg {
      margin-right: 0.5rem;
    }
    &.twitter {
      background: rgb(29, 161, 242);
    }
    @media only screen and (min-width: 500px) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  button {
    &.share_button {
      background: rgb(107, 168, 100);
    }
    &.archived_games_button {
      background: #f0a868;
      color: #000;
    }
    &.play_weekly_50_button {
      background: #f25757;
      color: #fff;
    }
  }
  &.dark {
    button {
      &.global_stats_button {
        background: transparent;
        border: 1px solid #fff;
      }
    }
  }
}

.all_guesses_modal {
  .all_guesses_container {
    margin-bottom: 1rem;
  }

  .total_plays_modal_statement {
    margin: 1.5rem 0;
  }

  .bottom_buttons_container {
    margin-top: 2rem;
    padding-bottom: 2rem;
  }
}
