@font-face {
  font-family: Nimbus Sans Extended D;
  src: url("../src/assets/fonts/NimbusSansExtdDLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: Nimbus Sans Extended D;
  src: url("../src/assets/fonts/NimbusSansExtdDReg.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Futura Std Book;
  src: url("../src/assets/fonts/FuturaStdBook.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Nimbus Sans Extended D;
  src: url("../src/assets/fonts/NimbusSansExtdDBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Nimbus Sans Extended D;
  src: url("../src/assets/fonts/NimbusSansExtdDBlack.otf") format("opentype");
  font-weight: 800;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transition: background 0.4s ease;
  &.dark {
    background: #0e1117;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img:not(div *),
iframe:not(div *) {
  display: none;
}

.carousel.carousel-slider {
  overflow: visible !important;
  @media only screen and (max-width: 768px) {
    overflow: hidden !important;
  }
}

.carousel {
  .thumb {
    transition: none !important;
    border: 1px solid transparent !important;
  }

  .thumb:focus {
    border: 1px solid transparent !important;
  }

  .thumb.selected {
    border: 1px solid #333 !important;
  }

  .thumb:hover:not(.selected) {
    border: 1px solid transparent !important;
  }

  li.slide {
    height: 300px;
    div {
      height: 100%;
    }
  }
}
