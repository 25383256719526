.contact_container {
  position: relative;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  background: #fff;
  box-sizing: border-box;
  transition: background 0.4s ease, color 0.4s ease;
  top: 3rem;
  padding-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    padding-bottom: 10rem;
  }

  &.dark {
    background: #161b22;
    color: #fff;
    @media only screen and (min-width: 32rem) {
      outline: 1px solid #30363d;
    }
  }

  .contact_title_header {
    position: relative;
    height: 3rem;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 1px solid #30363d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    background: #323232;
    color: #fff;

    &.dark {
      background: #0e1117;
    }
  }

  .contact_form {
    margin-top: 1.5rem;
    width: 100%;
    padding-bottom: 3rem;
    background: #e6e6e6;
    &.dark {
      background: none;
    }
    @media only screen and (max-width: 900px) {
      padding-bottom: 10rem;
    }
  }

  .contact_input_container {
    textarea {
      resize: none;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }
    .form-control {
      background: #fff;
      color: #000;
      &:focus {
        border-color: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    &.dark {
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      .form-control {
        background: #000;
        color: #fff;
      }
    }
  }

  .contact_success_message_container {
    color: rgb(84, 155, 2);
    background: #fff;
    font-weight: 700;
    p {
      padding: 0.75rem;
    }

    &.dark {
      background: #0e1117;
      color: rgb(104, 175, 22);
      outline: 1px solid rgb(100, 100, 100);
      p {
        color: rgb(107, 168, 100) !important;
      }
    }
  }
}
