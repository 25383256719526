.modal_statistics_countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  span {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0.05em;
  }
}

.graph_distribution_container {
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #ccc;
  h3 {
    font-weight: 500 !important;
  }
}

.statistics_outer_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0.5rem;

  .statistics_inner_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h3 {
      font-family: Futura Std Book;
      font-size: 36px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.01em;
      font-variant-numeric: proportional-nums;
      margin: 0;
      padding: 0;
      padding-bottom: 0.1rem;
    }

    div {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0;
      padding: 0;
    }

    .statistics_label {
      font-family: Futura Std Book;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 59px;
      height: 30px;
    }
  }

  &.dark {
    h3,
    .statistics_label {
      color: #fff;
    }
  }
}
