@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.modal_contents {
  background: #fff;
  transform: translateY(150%);
  transition: transform 0.5s ease;
  max-width: 520px;
  padding: 32px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 75px auto 0 auto;
  &.show_modal {
    transform: translateX(0%);
  }
  &.statistics_modal,
  &.support_modal {
    padding-bottom: 5rem;
  }
  &.how_to_play_modal {
    padding-bottom: 3rem;
  }

  @media only screen and (max-width: 500px) {
    height: 100%;
    min-height: 70%;
    width: 100%;
  }

  h2 {
    font-family: Futura Std Book;
    font-weight: 700 !important;
    margin-top: 29px;
    margin-bottom: 4px;
  }

  h3 {
    font-family: "Helvetica Neue";
    font-weight: 400 !important;
    font-size: 1.25rem;
  }

  .close_modal_button {
    background: none;
    border: none;
    padding: 0;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0.75rem;
    right: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .game_instructions_container {
    border-bottom: 1px solid #ccc;
    .how_to_play_list {
      padding-left: 1rem;
      margin: 1.25rem 0;
      li {
        .feedback_statements_container {
          margin: 0.5rem 0;
        }
        p.feedback_statement {
          margin: 0;
          span.symbol_element {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .game_countdown_timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    font-size: 1.25rem;
    p {
      margin: 0;
    }
    .time_disclaimer {
      font-size: 0.65rem;
      text-align: center;
    }
    span {
      font-weight: 700;
    }
  }

  .modal_play_button {
    width: 50%;
    color: #fff;
    background: #000;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 700;
    height: 2.875rem;
    margin: 0;
    margin-top: 1.5rem;
    margin-bottom: 29px;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.5s ease;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    @media only screen and (min-width: 500px) {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .modal_play_button {
    background: rgb(107, 168, 100);
    color: #fff;
  }

  &.dark {
    background: #161b22;
    h2,
    h3,
    li,
    p {
      color: #fff;
    }
  }
}
