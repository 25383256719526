.weekly_50_countdown_timer_container {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  background: rgb(245, 245, 245);
  outline: 1px solid rgb(180, 180, 180);
  padding: 0.5rem 0;
  &.dark {
    color: #fff;
    background: rgb(35, 35, 35);
    outline: 1px solid rgb(75, 75, 75);
  }
  .weekly_50_countdown_timer {
    font-size: 1.5rem;
    font-weight: 700;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .weekly_50_time_disclaimer {
    font-size: 0.6rem;
    p {
      margin: 0;
      text-align: center;
    }
  }
}

.weekly_50_week_of {
  font-size: 0.85rem;
}

.weekly_50_row {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  display: flex;
  margin-bottom: 1rem;
  border: 1px solid #000;
  p {
    margin: 0;
  }
  .left_row_container,
  .right_row_container {
    display: flex;
    align-items: center;
    width: 47px;
    min-width: 47px;
    &.play_button {
      cursor: pointer;
    }
    .score_divider_column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 390px) {
        margin-right: 10%;
      }
      p {
        font-size: 0.9rem;
        font-weight: 700;
        text-align: center;
        width: 100%;
        &:last-child {
          border-top: 1px solid #000;
        }
      }
    }
  }
  .right_row_container {
    border-left: 1px solid #000;
    &.dark {
      border-left: 1px solid #fff;
      p {
        &:last-child {
          border-top: 1px solid #fff;
        }
      }
    }
  }
  .game_num_container {
    align-items: center;
    color: #000;
    display: flex;
    height: 2rem;
    justify-content: center;
    margin: 0 auto 0 0.5rem;
    width: 2rem;
    font-weight: 700;
  }
  button.show_game_details {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #000;
    border-top: none;
    border-right: 0;
    border-bottom: none;
    color: #000;
    font-weight: 700;
    p {
      margin-right: 1rem;
      @media only screen and (max-width: 768px) {
        font-size: 0.9rem;
        margin-right: 0.5rem;
      }
    }
  }
  &.dark {
    border: 1px solid #fff;
    button.show_game_details {
      border-left: 1px solid #fff;
    }
  }
  &.loss {
    background: rgba(238, 51, 34, 0.3);
  }
  &.win {
    background: rgba(107, 168, 100, 0.3);
  }
  @media only screen and (min-width: 650px) {
    &:hover {
      background: rgb(235, 235, 235);
      &.dark {
        background: rgb(50, 50, 50);
      }
      &.loss {
        background: rgba(238, 51, 34, 0.2);
      }
      &.win {
        background: rgba(107, 168, 100, 0.2);
      }
    }
  }
}

.weekly_50_game_details {
  height: 100%;
  border: 1px solid #000;
  border-top: none;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media only screen and (min-width: 450px) {
    grid-template-columns: 1fr 3fr;
  }
  &.dark {
    border: 1px solid #fff;
    border-top: none;
  }
  .weekly_50_product_image_container {
    display: flex;
    width: 100px;
    min-width: 100px;
    height: 100px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    margin: 1rem 0;
    margin-left: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
    img {
      width: 100%;
      object-fit: contain;
      border: 1px solid rgb(185, 185, 185);
    }
    &.dark {
      img {
        border: 1px solid rgb(225, 225, 225);
      }
    }
  }
  .product_text_details_container {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000;
    &.dark {
      color: #fff;
    }
    .weekly_50_brand,
    .weekly_50_description {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0 0.4rem;
      span {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      span:first-child::first-letter {
        text-transform: uppercase;
      }
    }
    .weekly_50_brand {
      font-size: 1.1rem;
      margin: 0;
    }
    .weekly_50_description {
      gap: 0 0.3rem;
      font-size: 0.8rem;
    }
    .weekly_50_price {
      font-weight: 700;
      margin: 0;
      display: flex;
      align-self: flex-end;
    }
    .hidden {
      filter: blur(6px);
      font-weight: 700;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .weekly_50_buttons_container {
    position: relative;
    width: 100%;
  }
}

.weekly_50_buttons_container {
  .product_purchase_button {
    position: relative;
    width: 100%;
    margin: -1rem 0 1rem 0;
    background: #fff;
    a {
      color: #000;
    }
    box-sizing: border-box;
    border: 1px solid #000;
  }

  .weekly_50_play_button {
    width: 100%;
    min-width: 236px;
    max-width: 236px;
    font-weight: 700;
    margin: 1rem auto;
    background: rgb(107, 168, 100);
    cursor: pointer;
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    &.no_purchase_button {
      margin-bottom: 0;
    }
  }

  .return_to_todays_game {
    width: 100%;
    max-width: 100%;
    min-width: auto;
    margin: 1rem 0 2.5rem 0;
    position: relative;
    border: 1px solid #fff;
    background: rgb(85, 85, 85);
    svg {
      position: absolute;
      left: 1rem;
    }
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
