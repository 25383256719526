/* Position and sizing of burger button */
.bm-burger-button {
  z-index: 5 !important;
  position: absolute;
  width: 28px;
  height: 100%;
  left: 0.9rem;
  top: -0.05rem;
  bottom: 0;
  margin: 0 auto;
  svg {
    width: auto !important;
    height: auto !important;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  @media only screen and (min-width: 768px) {
    margin-right: 1rem;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  @media only screen and (min-width: 400px) and (min-height: 1000px) {
    width: 400px !important;
  }
}

/* General sidebar styles */
.bm-menu {
  z-index: 999999999999 !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Individual item */
.bm-item {
  &:not(.kofi_button_container) {
    display: flex;
    align-self: flex-start;
  }

  &.kofi_button_container {
    margin-top: -2rem;
  }
}

/* Styling of overlay */
.bm-overlay {
  backdrop-filter: blur(3px);
  top: 0;
}

@mixin hover-element {
  @media only screen and (min-width: 768px) {
    &:hover {
      cursor: pointer;
      color: rgb(200, 200, 200);
      svg {
        color: rgb(200, 200, 200);
        path {
          stroke: rgb(200, 200, 200);
        }
      }
      fill: rgb(200, 200, 200);
    }

    &:focus,
    &:active {
      cursor: pointer;
      color: rgb(200, 200, 200);
      svg {
        color: rgb(200, 200, 200);
        path {
          stroke: rgb(200, 200, 200);
        }
      }
      fill: rgb(150, 150, 150);
    }
  }
}

.logo_container_burger {
  position: relative;
  width: 100%;
  color: #000;
  h1.burger_logo {
    font-family: Nimbus Sans Extended D;
    font-weight: 700 !important;
    color: #000;
    margin: 0;
  }
  &.burger_menu {
    width: 100%;
    max-width: 15rem;
  }
  p {
    font-size: 0.75rem;
    margin: 0;
  }

  &.dark {
    color: #fff;
    p,
    h1.burger_logo {
      color: #fff;
    }
  }
}

.burger_menu_item_container {
  padding: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  .menu-item {
    text-decoration: none;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin: 3rem 0 3rem 4rem;
    transition: 0.5s ease margin-left;
    a {
      display: flex;
      text-decoration: none;
      color: #fff;
    }
    @media only screen and (min-width: 400px) and (min-height: 1000px) {
      margin-left: 7.5rem;
    }
    cursor: pointer;
    &:not(:first-child) {
      margin-top: 0rem;
    }
    color: #fff;
    &:not(.set_dark_mode) {
      font-size: 1.15em;
    }

    &.set_dark_mode {
      font-family: "Helvetica";
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-top: 3rem;
      margin-left: 0;
    }

    label {
      margin-bottom: 0;
    }

    svg {
      min-width: 25px;
      max-width: 25px;
    }

    .home_icon {
      margin-top: 0.2rem;
    }

    .contact_icon {
      path {
        stroke: #000;
      }
    }

    p {
      margin: 0 0.85rem;
      color: #000;
      &:hover {
        color: rgb(100, 100, 100);
      }
    }
  }
  &.dark {
    .contact_icon {
      path {
        stroke: #fff;
      }
    }
    p {
      color: #fff;

      &:hover {
        color: rgb(200, 200, 200);
      }
    }
  }
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.burger_other_projects_container {
  display: flex !important;
  flex-direction: column;
  align-self: flex-end;
  text-align: left;
  margin: 0 2rem;
  margin-top: 8vh;
  padding-bottom: 5rem;
  color: #fff;

  @media only screen and (min-height: 1000px) {
    margin-top: 10vh;
  }

  @media only screen and (min-height: 1100px) {
    margin-top: 20vh;
  }

  @media only screen and (min-height: 1200px) {
    margin-top: 25vh;
  }

  p.created_by {
    font-size: 0.75rem;
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid #000;
    padding-bottom: 1px;
    color: #000;
    transition: color 0.4s ease, border-bottom-color 0.4s ease;

    @media only screen and (min-width: 992px) {
      &:hover {
        color: rgb(225, 225, 225);
        border-bottom: 1px solid rgb(225, 225, 225);
        cursor: pointer;
      }

      &:focus,
      &:active {
        color: rgb(200, 200, 200);
        border-bottom: 1px solid rgb(200, 200, 200);
        cursor: pointer;
      }
    }
  }

  h2 {
    font-size: 0.7rem;
    color: #fff;
  }

  p {
    color: #000;
    font-size: 0.6rem;
  }

  &.dark {
    a {
      border-bottom: 1px solid #fff;
    }

    a,
    h2,
    p {
      color: #fff;
    }
  }
}

.policies_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  a {
    color: #000 !important;
    border-bottom: 1px solid #000;
    @media only screen and (min-width: 992px) {
      &:hover {
        color: rgb(50, 50, 50) !important;
        border-bottom: 1px solid rgb(50, 50, 50);
      }
      &:focus,
      &:active {
        color: rgb(80, 80, 80) !important;
        border-bottom: 1px solid rgb(80, 80, 80);
        cursor: pointer;
      }
    }
  }
  &.dark {
    a {
      color: #fff !important;
      border-bottom: 1px solid #fff;
      @media only screen and (min-width: 992px) {
        &:hover {
          color: rgb(225, 225, 225) !important;
          border-bottom: 1px solid rgb(225, 225, 225);
          cursor: pointer;
        }
        &:focus,
        &:active {
          color: rgb(200, 200, 200) !important;
          border-bottom: 1px solid rgb(200, 200, 200);
          cursor: pointer;
        }
      }
    }
  }

  li,
  a {
    text-decoration: none;
    cursor: pointer;
    margin-top: 2rem;
    font-size: 0.85rem;
    text-align: center;
    padding-bottom: 0.3rem;
  }
}

.burger_support_buttons_container {
  margin-top: 2rem;
  display: flex !important;
  flex-direction: column;
  align-self: center !important;
}

.kofi-button {
  min-width: 175px !important;
}

.winner_kofi_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  p {
    font-weight: 700;
  }

  svg {
    margin-right: 0.25rem;
  }

  @media only screen and (max-width: 768px) {
    .btn-container.share {
      min-width: auto;
    }
  }
}
