.guess_area {
  max-width: 480px;
  position: relative;
  margin: 0 auto;
  width: 100%;
  background: #fff;
  &.hidden {
    opacity: 0;
  }

  .guess_input_group {
    display: flex;
    position: relative;
    margin: 2rem 0 0.5rem 0;
    input.guess_input {
      font-family: "Helvetica Neue";
      width: calc(100% - 2rem);
      height: 2.875rem !important;
      border: 1px solid #78797a;
      background: transparent;
      outline: none;
      padding-left: 0.5rem;
      &.withValue {
        padding-left: 1.25rem;
      }
      &.dark {
        background: rgb(25, 25, 25);
        color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0;
      }
      &:focus {
        border-color: #66afe9;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
        + .enter_container {
          border-color: #66afe9;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 2px rgba(102, 175, 233, 0.6);
        }
      }
    }
    .input_dollar_sign {
      font-family: "Helvetica Neue";
      display: none;
      &.visible {
        display: flex;
      }
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.5rem;
      margin: 0 auto;
      color: #000;
      &.dark {
        color: #fff;
      }
    }
    .enter_container {
      cursor: pointer;
      position: absolute;
      right: 0;
      background: #000;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.875rem;
      width: 2.875rem;
      border: 1px solid #000;
      @media only screen and (max-width: 768px) {
        width: 5rem;
        font-size: 1.5rem;
      }
      &.dark {
        color: #fff;
        border: 1px solid #ced4da;
      }
    }
  }
}
